import React, { useState } from "react";
import { connect } from "react-redux";
import "./smjestaj.styles.css";

import PocetnaSlika from "../../components/Pocetna slika/pocetnaSlika";
import img from "../../fotografije/smjestaj.jpg";
import SmjestajOpcije from "../../components/Smjestaj opcije/smjestaj-opcije.component";
import SmjestajPonuda from "../../components/Smjestaj ponuda/smjestaj-ponuda.component";

const naslov = [
  { srp: "Двокреветна соба", eng: "Two-bed room" },
  { srp: "Трокреветна соба", eng: "Three-bed room" },
  { srp: "Четвокреветна соба", eng: "Four-bed room" },
  { srp: "Апартман Радоња", eng: "Apartment Radonja" },
];

const Smjestaj = ({ jezik, setLoading }) => {
  const [soba, setSoba] = useState(0);

  return (
    <div>
      <PocetnaSlika
        slika={img}
        naslov={jezik === "srpski" ? "Смјештај" : "Accommodation"}
        setLoading={setLoading}
      />

      <div className="container4">
        <div className="row-smjestaj">
          <div className="column-45">
            <div>
              <SmjestajOpcije
                soba={soba}
                setSoba={setSoba}
                naslov={naslov}
                jezik={jezik}
              />
            </div>
          </div>
          <div className="column-55">
            <SmjestajPonuda soba={soba} naslov={naslov} />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ jezik }) => ({
  jezik: jezik.jezik,
});

export default connect(mapStateToProps)(Smjestaj);
