import JezikActionTypes from './jezik.types';

export const promijeniSrpski = () => ({
    type: JezikActionTypes.PROMIJENI_SRPSKI, 
    payload: "engleski",
});

export const promijeniEngleski = () => ({
    type: JezikActionTypes.PROMIJENI_ENGLESKI, 
    payload: "srpski",
});