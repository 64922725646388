import React from 'react';
import {connect} from 'react-redux';
import { useHistory } from "react-router-dom";

import styled from 'styled-components';
import Burger from './Burger/Burger';
import { ImMobile } from 'react-icons/im';
import { FiMail } from 'react-icons/fi';
import Facebook from './Facebook/Facebook';
import Instagram from './Instagram/Instagram';
import Jezik from './Jezik/jezik.component';
import BookingButton from './Book dugme/bookingButton.component';
import LogoHorizontalni from '../../logo/horizontalno.svg';

import './zaglavlje.styles.css';


const Nav = styled.nav`
  width: 100%;
  height: 55px;
  border-top: 2px solid #f1f1f1;
  padding-left: 10%;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  margin-left: 0%;
  .logo {
    left: 13%;
    padding: 15px 0;
    font-size: large;
    position: relative;
  }
`
const Info = styled.div`
width: 100%;
height: 35px;
padding: 0 20px;
display: flex;
margin-left: 12.5%;


.verticalLine{
  border-left: 2px solid #f1f1f1;
  margin-left: 2%;
  margin-right: 2%;
}

h4{
  margin: 0.6% 0;
  position: relative;
}

@media (max-width: 1200px) {
  display: none;
}

.tel{
  margin-bottom: 3%;
  color: green;
}
.mail{
  margin-bottom: 3%;
  color: green;
}

`;

const Zaglavlje = ({jezik}) => {
  
  let history = useHistory();

  return (
    <div className='zaglavlje'>
      <Info>
          <h4> <ImMobile className='tel'/> +382 (0) 69 047 834 </h4>     
          <div className='verticalLine'></div>
          <h4> <FiMail className = 'mail'/> lucplus@t-com.me</h4>
          <Instagram/>
          <Facebook />
          <BookingButton>{jezik === 'engleski' ? 'Book now' : 'Резервиши'}</BookingButton>       
          <Jezik />
      </Info>
    <Nav>
      <div className="logo">
        <img src={LogoHorizontalni} alt="logo" width={200} onClick={() => {history.push('/')}}/>
      </div>
      <Burger />
    </Nav>
    </div>
  )
};

const mapStateToProps = ({jezik}) => ({
  jezik: jezik.jezik
});

export default connect(mapStateToProps)(Zaglavlje);
