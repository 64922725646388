import React from "react";
import { connect } from "react-redux";

import "./o-nama.styles.css";
import PocetnaSlika from "../../components/Pocetna slika/pocetnaSlika";
import img from "../../fotografije/o-nama.jpg";
import Naslov from "../../components/Naslov/naslov.component";
import Tekst from "../../components/Tekst/tekst.component";
import Lokacija from "../../components/Lokacija/lokacija.component";

const ONama = ({ jezik, setLoading }) => (
  <div className="o-nama">
    <PocetnaSlika
      slika={img}
      naslov={jezik === "srpski" ? "О нама" : "About us"}
      setLoading={setLoading}
    />
    <Tekst>
      {jezik === "srpski"
        ? 'Етно село "Ока и по" налази се на изузетно лијепој локацији, поред планинске ријеке, окружено планинама. У предјелу гдје расте листопадна и четинарска шума, па се може рећи да припада ваздушним бањама. У селу се гаје све врсте домаћих животиња. Село је изграђено у трдицијалном стилу од природних материјала.'
        : 'Ethno village "Oka i po" is located in an extremely beautiful location, next to a mountain river, surrounded by mountains. In the area where deciduous and coniferous forests grow, it can be said that it belongs to air spas. All kinds of domestic animals are raised in the village. The village is built in the traditional style of natural materials.'}
    </Tekst>
    <Naslov>{jezik === "srpski" ? "Наша визија" : "Our vision"}</Naslov>
    <Tekst>
      {jezik === "srpski"
        ? "Наша визија је развој сеоског туризма, као и отварње нових, до садa неискоришћених туристичких потенцијала. Претварили смо комплетан крај у туристичку понуду, који је идеалан за активан одмор. Ока и по представља идеално мјесто за одмор свим заљубљеницима у природу."
        : "Our vision is the development of rural tourism, as well as the opening of new, untapped tourist potentials. We have turned the entire area into a tourist offer, which is ideal for an active vacation. Oka i po is an ideal vacation place for all nature lovers."}
    </Tekst>
    <Naslov>{jezik === "srpski" ? "Локација" : "Location"}</Naslov>
    <Tekst>
      {jezik === "srpski"
        ? "Село Калудра, у којем се налази етно село „Ока и по“, удаљено је 13 км од Берана, на висини од 900 м надморске висине."
        : 'The village of Kaludra, where the farm stay "Oka i po" is located, is 13 km away from Berane, at an altitude of 900 m above sea level.'}
    </Tekst>
    <Lokacija />
  </div>
);

const mapStateToProps = ({ jezik }) => ({
  jezik: jezik.jezik,
});

export default connect(mapStateToProps)(ONama);
