import React from "react";
import "./bookingButton.styles.css";

import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import FormaSmjestaj from "../../forme/forma-smjestaj/forma-smjestaj.component";

const BookingButton = (props) => {
  if (props.smjestaj || props.kontakt || props.restoran) {
    return (
      <button
        className={
          props.kontakt
            ? "kontakt-dugme"
            : props.restoran
            ? "restoran-dugme"
            : "smjestaj-dugme"
        }
      >
        {props.children}
      </button>
    );
  }

  return (
    <Popup
      trigger={
        <button
          className={
            props.kontakt
              ? "kontakt-dugme"
              : props.open
              ? "book2"
              : props.restoran
              ? "restoran-dugme"
              : "book"
          }
        >
          {props.children}
        </button>
      }
      modal
      nested
    >
      {(close) => (
        <div className="rezervacija-forma">
          <button className="close" onClick={close}>
            &times;
          </button>
          <FormaSmjestaj />
        </div>
      )}
    </Popup>
  );
};

export default BookingButton;
