import React, { useEffect } from "react";
import "./ponuda-za-goste.styles.css";
import { connect } from "react-redux";
import $ from "jquery";

import PocetnaSlika from "../../components/Pocetna slika/pocetnaSlika";
import img from "../../fotografije/ponuda.jpg";
import Naslov from "../../components/Naslov/naslov.component";
import ljuljaska from "./ponuda fotografije/ljuljaska.jpg";
import Tekst from "../../components/Tekst/tekst.component";
import bazen from "./ponuda fotografije/bazen.jpg";
import cetvorotockas from "./ponuda fotografije/cetvorotockas.jpg";
import jezero from "./ponuda fotografije/jezero.jpg";
import kamp from "./ponuda fotografije/kamp.jpg";
import ribolov from "./ponuda fotografije/ribolov.jpg";
import manastir from "./ponuda fotografije/manastir.jpg";
import kape from "./ponuda fotografije/kape.jpg";
import {
  GiFishing,
  GiCaravan,
  GiMountainRoad,
  GiWaterSplash,
  GiTwirlyFlower,
  GiTreeSwing,
} from "react-icons/gi";
import { BiCycling } from "react-icons/bi";
import { FaSwimmer, FaChurch } from "react-icons/fa";

window.addEventListener("resize", function () {
  for (var i = 0; i < 4; i++) {
    var manji = $(".row-ponuda").find(`.promjena33-${i}`);
    var veci = $(".row-ponuda").find(`.promjena66-${i}`);

    if (window.innerWidth <= 997 && veci.index() < manji.index()) {
      veci.detach().insertAfter(manji);
    } else if (window.innerWidth >= 997) {
      veci.detach().insertBefore(manji);
    }
  }
});

const PonudaZaGoste = ({ jezik, setLoading }) => {
  function isScrolledIntoView(elem) {
    var docViewTop = $(window).scrollTop();
    var docViewBottom = docViewTop + $(window).height();

    var elemTop = $(elem).offset().top;
    var elemBottom = elemTop + $(elem).height();

    return elemBottom <= docViewBottom && elemTop >= docViewTop;
  }

  $("html, body").scroll(function () {
    $(".slika-za-tekst").each(function () {
      if (isScrolledIntoView($(this))) {
        this.classList.add("zoom");
      }
    });
  });

  useEffect(() => {
    if (window.innerWidth <= 997) {
      for (var i = 0; i < 4; i++) {
        var manji = $(".row-ponuda").find(`.promjena33-${i}`);
        var veci = $(".row-ponuda").find(`.promjena66-${i}`);
        manji.detach().insertBefore(veci);
      }
    }
  }, []);

  return (
    <div className="ponuda-za-goste">
      <PocetnaSlika
        slika={img}
        naslov={jezik === "srpski" ? "Понуда за госте" : "Offer for guests"}
        setLoading={setLoading}
      />
      <Naslov>
        {jezik === "srpski"
          ? "Пуно узбудљивог и инспиративног садржаја"
          : "Lots of exciting and inspiring content"}
      </Naslov>
      <Tekst>
        {jezik === "srpski"
          ? "Oсим пријатне атмосфере, удобног смjештаја и домаће хране, својим гостима нудимо и додатан садржај и препоруке како би боравак био што занимљивији."
          : "In addition to a pleasant atmosphere, comfortable accommodation and local food, we offer our guests additional content and recommendations to make their stay as interesting as possible."}
        <br />
        {jezik === "srpski"
          ? "Нудимо садржаје за све врсте гостију, како за одрасле тако и за дјецу."
          : "We offer interesting content for all types of guests, both adults and children."}
      </Tekst>

      <div className="container3">
        <div className="row-ponuda">
          <div className="column-66 promjena66-0">
            <div>
              <img className="slika-za-tekst" src={kamp} alt="" />
            </div>
          </div>
          <div className="column-33 promjena33-0">
            <div className="tekst-ponuda">
              <GiCaravan className="icon-ponuda" />
              <h1>{jezik === "srpski" ? "Ауто камп" : "The car camp"} </h1>
              <Tekst>
                {jezik === "srpski"
                  ? "Ауто камп посједује тоалет, мјесто за роштиљ, прикључак за струју и воду."
                  : "The car camp has a toilet, barbecue area, electricity and water connection."}
              </Tekst>
            </div>
          </div>
        </div>
      </div>

      <div className="container3">
        <div className="row-ponuda">
          <div className="column-33">
            <div className="tekst-ponuda">
              <GiFishing className="icon-ponuda" />
              <h1>{jezik === "srpski" ? "Риболов" : "Fishing"}</h1>
              <Tekst>
                {jezik === "srpski"
                  ? "Планине, долине, ријеке, извори чисте воде изузетног квалитета чине овај простор неодољивим. Ријека која је настала од више врела, пуна је поточне пастремке. Идеална за заљубљенике пецања на брзим планинским ријекама."
                  : "Mountains, valleys, rivers, springs of clean water of exceptional quality make this area irresistible. The river, which originated from several springs, is full of brown trout. Ideal for lovers of fishing on fast mountain rivers."}
              </Tekst>
            </div>
          </div>
          <div className="column-66">
            <div>
              <img className="slika-za-tekst" src={ribolov} alt="" />
            </div>
          </div>
        </div>
      </div>

      <div className="container3">
        <div className="row-ponuda">
          <div className="column-66 promjena66-1">
            <div>
              <img className="slika-za-tekst" src={kape} alt="" />
            </div>
          </div>
          <div className="column-33 promjena33-1">
            <div className="tekst-ponuda">
              <GiMountainRoad className="icon-ponuda" />
              <h1>
                {jezik === "srpski" ? "Планинарење" : "Mountain climbing"}{" "}
              </h1>
              <Tekst>
                {jezik === "srpski"
                  ? "Од самог изласка из села постоји изузетан потез за заљубљеника пешачења. Пешачи се кањонима ријека кроз шумске предјеле до изласка на планине и пењања на врхове од преко 1900 м. На траси можете срести разне шумске плодове, љековито биље као и неколико врста јестивих гљива."
                  : "From the very exit from the village, there is an exceptional move for a hiking enthusiast. Hikers through river canyons through forest areas to the mountains and climbing to peaks of over 1900 m. On the route you can find various forest fruits, medicinal herbs and several types of edible mushrooms."}
              </Tekst>
            </div>
          </div>
        </div>
      </div>

      <div className="container3">
        <div className="row-ponuda">
          <div className="column-33">
            <div className="tekst-ponuda">
              <BiCycling className="icon-ponuda" />
              <h1>
                {jezik === "srpski"
                  ? "Вожња бицикла и четвороточкаша"
                  : "Riding bikes and four-wheelers"}
              </h1>
              <Tekst>
                {jezik === "srpski"
                  ? "Атрактивна локација за све љубитеље: хајкинга, бајкинга, моунтебајкинга као и за заљубљенике у мотоциклизам. Једна од четири најатрактивније бициклистичке, џип и мотористичке стазе пролази управо кроз наше село."
                  : "Attractive location for all lovers: hiking, biking, mountain biking as well as for motorcycling enthusiasts. One of the four most attractive bicycle, jeep and motorcycle trails passes right through our village."}
                <br />
                <br />
                {jezik === "srpski"
                  ? "Својим гостима нудимо изнајмљивање четвороточкаша и бицикала."
                  : "We offer our guests the rental of four-wheelers and bicycles."}
              </Tekst>
            </div>
          </div>
          <div className="column-66">
            <div>
              <img className="slika-za-tekst" src={cetvorotockas} alt="" />
            </div>
          </div>
        </div>
      </div>

      <div className="container3">
        <div className="row-ponuda">
          <div className="column-66 promjena66-2">
            <div>
              <img className="slika-za-tekst" src={bazen} alt="" />
            </div>
          </div>
          <div className="column-33 promjena33-2">
            <div className="tekst-ponuda">
              <FaSwimmer className="icon-ponuda" />
              <h1>
                {jezik === "srpski" ? "Базен за купање" : "Swimming pool"}
              </h1>
              <Tekst>
                {jezik === "srpski"
                  ? "Испред хотела се налази базен, који у свом саставу има и базенчић за дјецу. Поред њега су отворени шанк и столови, гдје гости могу да сједну и освјеже се. "
                  : "In front of the hotel there is a swimming pool, which also has a swimming pool for children. Next to it are open bar and tables, where guests can sit and refresh themselves."}
              </Tekst>
            </div>
          </div>
        </div>
      </div>

      <div className="container3">
        <div className="row-ponuda">
          <div className="column-33">
            <div className="tekst-ponuda">
              <FaChurch className="icon-ponuda" />
              <h1>{jezik === "srpski" ? "Манастири" : "Monasteries"} </h1>
              <Tekst>
                {jezik === "srpski"
                  ? "На 2 километра од етно села ћете имати прилику да обиђете манастир Ћелије, посвећен Светом Луки, из 14. вијека."
                  : "At 2 kilometers from the farm stay, you will have the opportunity to visit the monastery Celije, dedicated to St. Luke, from the 14th century."}
                <br />
                {jezik === "srpski"
                  ? "12 километара даље је манастир Ђурђеви Ступови, изграђен 1213. године, симбол је града Берана и свакако најзнаменитије мјесто које не бисте требали мимоићи."
                  : "12 kilometers away is the monastery Đurđevi Stupovi, built in 1213, is a symbol of the city of Berane and certainly the most famous place that you should not miss."}
              </Tekst>
            </div>
          </div>
          <div className="column-66">
            <div>
              <img className="slika-za-tekst" src={manastir} alt="" />
            </div>
          </div>
        </div>
      </div>

      <div className="container3">
        <div className="row-ponuda">
          <div className="column-66 promjena66-3">
            <div>
              <img className="slika-za-tekst" src={jezero} alt="" />
            </div>
          </div>
          <div className="column-33 promjena33-3">
            <div className="tekst-ponuda">
              <GiWaterSplash className="icon-ponuda" />
              <h1>{jezik === "srpski" ? "Језера" : ""}</h1>
              <Tekst>
                {jezik === "srpski"
                  ? "Обилазак катуна који имају у близини планинска језера."
                  : "A tour of the katuns that have mountain lakes nearby."}
                <ul className="jezera-ul">
                  <li>
                    <GiTwirlyFlower className="icon-jezera" />
                    {jezik === "srpski"
                      ? "Калударске капе - 10 км<"
                      : "Kaludarske kape - 6.4 miles"}
                  </li>
                  <li>
                    <GiTwirlyFlower className="icon-jezera" />
                    {jezik === "srpski"
                      ? "Пешића језеро - 30 км"
                      : "Pesica Lake - 18 miles"}
                  </li>
                  <li>
                    <GiTwirlyFlower className="icon-jezera" />
                    {jezik === "srpski"
                      ? "Шишко језеро - 25 км"
                      : "Šiška Lake - 15 miles"}
                  </li>
                  <li>
                    <GiTwirlyFlower className="icon-jezera" />
                    {jezik === "srpski"
                      ? "Плавско језеро - 40 км"
                      : "Lake Plav - 24 miles"}
                  </li>
                </ul>
              </Tekst>
            </div>
          </div>
        </div>
      </div>

      <div className="container3">
        <div className="row-ponuda">
          <div className="column-33">
            <div className="tekst-ponuda">
              <GiTreeSwing className="icon-ponuda" />
              <h1>
                {jezik === "srpski"
                  ? "Стрељана и игралиште за дјецу"
                  : "Shooting range and playground for children"}
              </h1>
              <Tekst>
                {jezik === "srpski"
                  ? "Љуљашка и тобоган за најмлађе. Импровизована стрељана у природи за старије."
                  : "Swing and slide for the little ones. Improvised shooting in nature for the elderly."}
              </Tekst>
            </div>
          </div>
          <div className="column-66">
            <div>
              <img className="slika-za-tekst" src={ljuljaska} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ jezik }) => ({
  jezik: jezik.jezik,
});

export default connect(mapStateToProps)(PonudaZaGoste);
