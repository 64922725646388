import React from 'react';
import {connect} from 'react-redux';
import {useHistory} from 'react-router-dom';

import './naslovna-o-nama.styles.css';
import {Container, Row, Col} from 'react-bootstrap';
import LogoGif from '../../logo/Gif logo sa slovima.gif';
import Naslov from '../Naslov/naslov.component';

const NaslovnaONama = ({jezik}) => {
  
  let history = useHistory();

  function handleONama(){
    if(jezik==='srpski'){
      history.push('/o-nama');
      window.scrollTo(0, 0);
    }else{
      history.push('/about-us');
      window.scrollTo(0, 0);
    }
  };
  
  return (
        <Container className='sve2'>
        <Row>
          <Col>
            <div>
                <Naslov>{jezik==='srpski' ? 'О нама' : 'About us'}</Naslov>
                <p className='opis'>
                  {jezik==='srpski' ?
                'Наша мисија је развој сеоског туризма, као и отварње нових, досад неискоришћених туристичких потенцијала.  Претварили смо комплетан крај у туристичку понуду, који је идеалан за активан одмор. Ока и по представља идеално мјесто за одмор свим заљубљеницима у природу...' : 
                'Our mission is the development of rural tourism, as well as the opening of new, untapped tourist potentials. We have turned the entire area into a tourist offer, which is ideal for an active vacation. Oka i po is an ideal vacation place for all nature lovers...'}
                    </p>   
                <a href={() => false} onClick={() => handleONama()} className='opsirnije' >{jezik==='srpski' ? 'ОПШИРНИЈЕ' : 'MORE'}</a>
            </div>
           <br/> <br/> <br/> <br/>
          </Col>
          <Col>
          <img src={LogoGif} alt="logo" className='gif-logo'  width={300}/>
          </Col>
        </Row>      
      </Container>
    )
}

const mapStateToProps = ({jezik}) => ({
  jezik: jezik.jezik
});


export default connect(mapStateToProps)(NaslovnaONama);
