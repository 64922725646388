import React, { useEffect } from "react";
import ComboBox from "react-responsive-combo-box";
import "react-responsive-combo-box/dist/index.css";
import "./kombo.styles.css";
import $ from "jquery";

const options = [
  "08:00h",
  "08:30h",
  "09:00h",
  "09:30h",
  "10:00h",
  "10:30h",
  "11:00h",
  "11:30h",
  "12:30h",
  "12:00h",
  "13:30h",
  "13:00h",
  "14:30h",
  "14:00h",
  "15:30h",
  "15:00h",
  "16:30h",
  "16:00h",
  "17:00h",
  "17:30h",
  "18:00h",
  "18:30h",
  "19:00h",
  "19:30h",
  "20:00h",
  "20:30h",
  "21:00h",
  "21:30h",
];

const options2 = [
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
];

const options3 = ["0", "1", "2", "3", "4", "5", "6", "7", "8"];

const Kombo = ({ placeholder, odrasli, djeca, sobe, name }) => {
  function hideKeyboard(element) {
    element.attr("readonly", "readonly"); // Force keyboard to hide on input field.
    element.attr("disabled", "true"); // Force keyboard to hide on textarea field.
    setTimeout(function () {
      element.blur(); //actually close the keyboard
      // Remove readonly attribute after keyboard is hidden.
      element.removeAttr("readonly");
      element.removeAttr("disabled");
    }, 100);
  }

  useEffect(() => {
    $("._3LDgJ").click(() => {
      hideKeyboard($("._3LDgJ"));
    });
  }, []);

  return (
    <ComboBox
      required={true}
      name={name}
      options={djeca || odrasli ? options2 : sobe ? options3 : options}
      placeholder={placeholder}
      defaultIndex={4}
      optionsListMaxHeight={300}
      style={
        odrasli
          ? {
              width: "94.5px",
              height: "53px",
              textAlign: "left",
              fontSize: "large",
              borderRadius: "0.25rem",
            }
          : djeca
          ? {
              width: "74px",
              height: "53px",
              textAlign: "left",
              fontSize: "large",
              borderRadius: "0.25rem",
            }
          : sobe
          ? {
              width: "101.5px",
              height: "53px",
              textAlign: "left",
              fontSize: "large",
              borderRadius: "0.25rem",
            }
          : {
              width: "91.5px",
              height: "53px",
              textAlign: "left",
              fontSize: "large",
              borderRadius: "0.25rem",
            }
      }
      focusColor="#20C374"
    />
  );
};

export default Kombo;
