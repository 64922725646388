import React from 'react';
import {connect} from 'react-redux';
import './dno.styles.css';
import {Container, Row, Col} from 'react-bootstrap';
import Facebook from '../zaglavlje/Facebook/Facebook';
import Instagram from '../zaglavlje/Instagram/Instagram';
import LogoCrni from '../../logo/logo crni.png';

const Dno = ({jezik}) => {
    return (
        <div className='dno'>
            <div className='predzadnji'>
            <Container  className='sve3'>
        <Row className="justify-content-md-center">
          <Col >
            <div className='lijevoDno'>
                <img src={LogoCrni} alt="logo" width={100}/>
                <p className='opisDno'>
               {jezik==='srpski' ? 'Запратите нас' : 'Follow us'}
                    </p>   
               <Facebook dno='true' />
               <Instagram dno='true'/>
            </div>
           
          </Col >
          <Col >
          <div className='desnoDno'>
            <h3 className='kontaktInfo'>{jezik==='srpski' ? 'КОНТАКТ ИНФО' : 'CONTACT INFO'}</h3>
          <h4 className='redovi'> +382 (0) 69 047 834 </h4>  
          <h4 className='redovi'>  lucplus@t-com.me</h4>
          <h4 className='redovi'> {jezik==='srpski' ? 'Калудра, Беране 84300,' : 'Kaludra, Berane 84300,'}</h4>
          <h4 className='redovi'>{jezik==='srpski' ? 'Црна Гора' : 'Montenegro'} </h4>
          </div>
          </Col>
        </Row>      
      </Container>        
                </div>
            <h3 className='zadnji'>Copyright © 2021 Farm stay Oka i po - All Rights Reserved.</h3>
        </div>
    )
}


const mapStateToProps = ({jezik}) => ({
  jezik: jezik.jezik
});

export default connect(mapStateToProps)(Dno);
