import React from 'react';
import {connect} from 'react-redux';
import './jezik.styles.css';

import cg from '../../../zastave/cg.png';
import eng from '../../../zastave/eng.png';
import { promijeniSrpski, promijeniEngleski } from '../../../redux/jezik/jezik.action';



const Jezik = ({jezik, promijeniEngleski, promijeniSrpski}) => {
  
  return (
        <div className='jezik'>
          <img src={cg} alt="srpski" className={jezik === 'srpski' ? 'aktivan' : 'neaktivan'} onClick= {() => {promijeniEngleski()}}/>
          <img src={eng} alt="engleski" className={jezik === 'engleski' ? 'aktivan' : 'neaktivan'} onClick= {() => {promijeniSrpski()}}/>
        </div>
    )
};

const mapStateToProps = ({jezik}) => ({
  jezik: jezik.jezik
}
);
const mapDispatchToProps = dispatch => ({
  promijeniEngleski: () => dispatch(promijeniEngleski()),
  promijeniSrpski: () => dispatch(promijeniSrpski())

});

export default connect(mapStateToProps, mapDispatchToProps)(Jezik);
