import React, { useEffect, useRef, useState } from "react";
import "./forma-smjestaj.styles.css";
import { connect } from "react-redux";

import emailjs from "emailjs-com";

import ReactJsAlert from "reactjs-alert";
import Input from "../../Input/input.component";
import Kombo from "../../Kombo/kombo.component";
import BookingButton from "../../zaglavlje/Book dugme/bookingButton.component";
import $ from "jquery";

const FormaSmjestaj = ({ jezik }) => {
  const form = useRef();
  const [alertVisible, setAlertVisible] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    let formProps = Object.fromEntries(formData);

    formProps = { ...formProps, type: "Смјештај", info: "/", time: "/" };

    emailjs
      .send(
        "gmail-service",
        "template_pkel8ss",
        formProps,
        process.env.REACT_APP_EMAILJS_USERID
      )
      .then(
        (result) => {
          console.log(result.text);
          setAlertVisible(true);
        },
        (error) => {
          console.log(error.text);
        }
      );

    e.target.reset();
  };

  useEffect(() => {
    if (window.innerWidth <= 531) {
      $('.forma-smjestaj > input[type="text"]').css({ width: "90%" });
      $('.forma-smjestaj > input[type="email"]').css({ width: "90%" });
    }

    $(window).resize(function () {
      if (window.innerWidth <= 531) {
        $('.forma-smjestaj > input[type="text"]').css({ width: "90%" });
        $('.forma-smjestaj > input[type="email"]').css({ width: "90%" });
      } else {
        $('.forma-smjestaj > input[type="text"]').css({ width: "70%" });
        $('.forma-smjestaj > input[type="email"]').css({ width: "70%" });
      }
    });
  }, []);

  return (
    <div className="forma-smjestaj">
      <div className="naslov-rezervacija">
        <h1>{jezik === "srpski" ? "Видимо се!" : "Welcome!"}</h1>
      </div>
      <form ref={form} onSubmit={sendEmail}>
        <Input
          plejsholder={jezik === "srpski" ? "Име и презиме" : "Fullname"}
          klasa={"smjestaj-input"}
          tip="text"
          name="name"
          required={true}
        />
        <Input
          plejsholder={jezik === "srpski" ? "Е-маил" : "E-mail"}
          klasa={"smjestaj-input"}
          tip="email"
          name="email"
          required={true}
        />
        <Input
          plejsholder={jezik === "srpski" ? "Број телефона" : "Phone number"}
          klasa={"smjestaj-input"}
          tip="text"
          name="mobile"
          required={true}
        />
        <div className="datumi">
          <div className="datum-od">
            <h5 className="dolazak">
              {jezik === "srpski" ? "Долазак:" : "Arrival:"}
            </h5>
            <Input
              tip="date"
              klasa={"smjestaj-input"}
              min={new Date()}
              max="2040-01-01"
              name="dateOf"
              required={true}
            />
          </div>
          <div className="datum-do">
            <h5 className="odlazak-datum">
              {jezik === "srpski" ? "Одлазак:" : "Departure:"}
            </h5>
            <Input
              tip="date"
              klasa={"smjestaj-input"}
              min={new Date()}
              max="2040-01-01"
              name={"dateTo"}
              required={true}
            />
          </div>
        </div>
        <div className="combo-rezervacija">
          <div>
            <Kombo
              placeholder={jezik === "srpski" ? "Одрасли" : "Adults"}
              odrasli={true}
              name="adults"
              required={true}
            />
          </div>
          <div>
            <Kombo
              placeholder={jezik === "srpski" ? "Дјеца" : "Kids"}
              djeca={true}
              name="kids"
              required={true}
            />
          </div>
          <div>
            <Kombo
              placeholder={jezik === "srpski" ? "Број соба" : "Rooms №"}
              sobe={true}
              name="rooms"
              required={true}
            />
          </div>
        </div>
        <BookingButton smjestaj="true">Резервишите</BookingButton>
      </form>
      <ReactJsAlert
        className="alert-box"
        button={jezik === "srpski" ? "У реду" : "Close"}
        status={alertVisible}
        type="success"
        title={
          jezik === "srpski"
            ? "Неко ће вас ускоро контактирати како би потврдили вашу резервацију. Хвала!"
            : "Someone will contact you shortly to confirm your reservation. Thank you!"
        }
        Close={() => setAlertVisible(false)}
      />
    </div>
  );
};

const mapStateToProps = ({ jezik }) => ({
  jezik: jezik.jezik,
});

export default connect(mapStateToProps)(FormaSmjestaj);
