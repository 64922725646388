import React from "react";
import "./smjestaj-ponuda.styles.css";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";

import Naslov from "../Naslov/naslov.component";
import Tekst from "../Tekst/tekst.component";
import { Row, Col } from "react-bootstrap";

import { HiWifi } from "react-icons/hi";
import { FaParking, FaMoneyBillAlt, FaTruckPickup } from "react-icons/fa";
import { MdPets, MdFreeBreakfast, MdPool, MdRestaurant } from "react-icons/md";
import { BsFillTvFill } from "react-icons/bs";
import { FiWind } from "react-icons/fi";
import { RiAlarmFill } from "react-icons/ri";
import {
  GiFlowerPot,
  GiSoap,
  GiWindowBars,
  GiBarbecue,
  GiClothes,
  GiBookshelf,
  GiTreeSwing,
  GiSawedOffShotgun,
  GiSandwich,
} from "react-icons/gi";

const opis = [
  {
    srp: "Делукс двокреветна соба са брачним креветом или два засебна кревета сапогледом на планине",
    eng: "Deluxe double or twin room with mountain views",
  },
  {
    srp: "Класична трокреветна соба са три одвојена кревета",
    eng: "Classic triple room with three separate beds",
  },
  {
    srp: "Четворокреветна соба са балконом, плазма ТВ-ом и интернетом.",
    eng: "This quadruple room features a balcony, flat-screen TV and private entrance.",
  },
  {
    srp: "Налази се у близини главног објекта, у ком ћете се ујутру будити уз цвркут птица и жуборење ријеке",
    eng: "It is located near main building, where you will wake up in the morning to the chirping of birds and the murmur of the river.",
  },
];

const SmjestajPonuda = ({ jezik, soba, naslov }) => {
  let history = useHistory();

  function handleClick() {
    if (jezik === "srpski") {
      history.push("/ponuda-za-goste");
    } else {
      history.push("/offer-for-guests");
    }
  }

  return (
    <div className="smjestaj-ponuda">
      <Naslov>
        {jezik === "srpski" ? naslov[soba].srp : naslov[soba].eng}
      </Naslov>
      <Tekst>{jezik === "srpski" ? opis[soba].srp : opis[soba].eng}</Tekst>
      <Row className="red">
        <Col className="logo-smjestaj-ponuda lsp">
          <p className="logo-smjestaj-sve">
            <MdFreeBreakfast className="logo-smjestaj" />
            {jezik === "srpski" ? "Бесплатан доручак" : "Free breakfast"}
          </p>
          <p className="logo-smjestaj-sve">
            <MdPets className="logo-smjestaj" />
            {jezik === "srpski" ? "Дозвољени љубимци" : "Pet friendly"}
          </p>
          <p className="logo-smjestaj-sve">
            <MdPool className="logo-smjestaj" />
            {jezik === "srpski" ? "Отворени базен" : "Outside pool"}
          </p>
          <p className="logo-smjestaj-sve">
            <MdRestaurant className="logo-smjestaj" />
            {jezik === "srpski" ? "Ресторан" : "Restaurant"}
          </p>
          <p className="logo-smjestaj-sve">
            <BsFillTvFill className="logo-smjestaj" />
            {jezik === "srpski" ? "Кабловска телевизија" : "Cable TV"}
          </p>
          <p className="logo-smjestaj-sve">
            <GiSandwich className="logo-smjestaj" />
            {jezik === "srpski" ? "Ланч пакети" : "Lunch packages"}
          </p>
          <p className="logo-smjestaj-sve">
            <FiWind className="logo-smjestaj" />
            {jezik === "srpski" ? "Клима" : "Air condition"}
          </p>
          <p className="logo-smjestaj-sve">
            <RiAlarmFill className="logo-smjestaj" />
            {jezik === "srpski" ? "Услуга буђења" : "Wake up service"}
          </p>
          <p className="logo-smjestaj-sve">
            <FaParking className="logo-smjestaj" />
            {jezik === "srpski" ? "Паркинг" : "Parking"}
          </p>
          <p className="logo-smjestaj-sve">
            <FaMoneyBillAlt className="logo-smjestaj" />
            {jezik === "srpski" ? "Мењачница" : "Exchange office"}
          </p>
        </Col>

        <Col className="logo-smjestaj-ponuda2 lsp2">
          <p className="logo-smjestaj-sve">
            <HiWifi className="logo-smjestaj" />
            {jezik === "srpski" ? "Бежични интернет" : "WiFi"}
          </p>
          <p className="logo-smjestaj-sve">
            <FaTruckPickup className="logo-smjestaj" />
            {jezik === "srpski" ? "Услуга превоза" : "Transport service"}
          </p>
          <p className="logo-smjestaj-sve">
            <GiFlowerPot className="logo-smjestaj" />
            {jezik === "srpski" ? "Башта" : "Garden"}
          </p>
          <p className="logo-smjestaj-sve">
            <GiSoap className="logo-smjestaj" />
            {jezik === "srpski" ? "Тоалетни прибор" : "Toiletries"}
          </p>
          <p className="logo-smjestaj-sve">
            <GiWindowBars className="logo-smjestaj" />
            {jezik === "srpski" ? "Тераса" : "Balcony"}
          </p>
          <p className="logo-smjestaj-sve">
            <GiBarbecue className="logo-smjestaj" />
            {jezik === "srpski" ? "Простор за роштиљ" : "Barbecue area"}
          </p>
          <p className="logo-smjestaj-sve">
            <GiClothes className="logo-smjestaj" />
            {jezik === "srpski" ? "Хемијско чишћење" : "Dry cleaning"}
          </p>
          <p className="logo-smjestaj-sve">
            <GiBookshelf className="logo-smjestaj" />
            {jezik === "srpski" ? "Библиотека" : "Library"}
          </p>
          <p className="logo-smjestaj-sve">
            <GiTreeSwing className="logo-smjestaj" />
            {jezik === "srpski" ? "Игралиште за дјецу" : "Children playground"}
          </p>
          <p className="logo-smjestaj-sve">
            <GiSawedOffShotgun className="logo-smjestaj" />
            {jezik === "srpski" ? "Стрељана" : "Shooting range"}
          </p>
          <div className="opsirnije-ponuda-div">
            <a
              href={() => false}
              onClick={() => handleClick()}
              className="opsirnije opsirnije-ponuda"
            >
              {jezik === "srpski"
                ? "ОПШИРНИЈЕ О ПОНУДИ"
                : "MORE ABOUT THE OFFER"}
            </a>
          </div>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = ({ jezik }) => ({
  jezik: jezik.jezik,
});

export default connect(mapStateToProps)(SmjestajPonuda);
