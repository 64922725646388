import React from 'react';
import './naslov.styles.css';


const Naslov = (props) => {
    return (
        <h1 className='vizija'>{props.children}</h1>
    )
}

export default Naslov;
