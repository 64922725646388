import React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import kolonija1 from "../../assets/slike za koloniju karusel/1.jpg";
import kolonija2 from "../../assets/slike za koloniju karusel/2.jpg";
import kolonija3 from "../../assets/slike za koloniju karusel/3.jpg";
import kolonija4 from "../../assets/slike za koloniju karusel/4.jpg";
import kolonija5 from "../../assets/slike za koloniju karusel/5.jpg";
import kolonija6 from "../../assets/slike za koloniju karusel/6.jpg";
import kolonija7 from "../../assets/slike za koloniju karusel/7.jpg";
import kolonija8 from "../../assets/slike za koloniju karusel/8.jpg";
import kolonija9 from "../../assets/slike za koloniju karusel/9.jpg";

import PocetnaSlika from "../../components/Pocetna slika/pocetnaSlika";
import img from "../../fotografije/organizacija-dogadjaja.jpg";
import Tekst from "../../components/Tekst/tekst.component";
import Naslov from "../../components/Naslov/naslov.component";
import Carousel from "../../components/karusel/Carousel.component";
import "./organizacija-dogadjaja.styles.css";

const OrganizacijaDogadjaja = ({ jezik, setLoading }) => {
  const slike = [
    {
      imageURL: kolonija1,
      number: 1,
    },
    {
      imageURL: kolonija2,
      number: 2,
    },
    {
      imageURL: kolonija3,
      number: 3,
    },
    {
      imageURL: kolonija4,
      number: 4,
    },
    {
      imageURL: kolonija5,
      number: 5,
    },
    {
      imageURL: kolonija6,
      number: 6,
    },
    {
      imageURL: kolonija7,
      number: 7,
    },
    {
      imageURL: kolonija8,
      number: 8,
    },
    {
      imageURL: kolonija9,
      number: 9,
    },
  ];

  let history = useHistory();

  function regulisiKontaktKlik() {
    if (jezik === "srpski") {
      history.push("/kontakt");
    } else {
      history.push("/contact");
    }
    window.scrollTo(0, 0);
  }

  return (
    <div className="org-dogadjaja">
      <PocetnaSlika
        slika={img}
        setLoading={setLoading}
        naslov={
          jezik === "srpski" ? "Организација догађаја" : "Event organizing"
        }
      />
      <Tekst className="opis-organizacija">
        {jezik === "srpski"
          ? 'Етно село "Ока и по" представља јединствени амбијент традиције, прилагођен савременим потребама својих гостију. Као такав представља савршено мјесто за организацију догађаја као што су: '
          : 'The ethno village "Oka i po" represents a unique ambience of tradition, adapted to the modern needs of its guests. As such, it is the perfect place to organize events such as:'}
        <section>
          <div className="lista-dogadjaja">
            <ul className="check-list">
              <li>{jezik === "srpski" ? "рођендани" : "birthdays"} </li>
              <li>{jezik === "srpski" ? "свадбе" : "weddings"} </li>
              <li>
                {jezik === "srpski" ? "пословни ручкови" : "business lunches"}{" "}
              </li>
              <li>{jezik === "srpski" ? "колоније" : "colonies"} </li>
              <li>{jezik === "srpski" ? "групне посјете" : "group visits"} </li>
              <li>"team buildings"</li>
            </ul>
          </div>
        </section>
        <br />
        <Tekst>
          {jezik === "srpski"
            ? "Уз љубазно особље и домаћу, органску храну направићете догађај за памћење."
            : "With friendly staff and homemade, organic food, you will create an event to remember."}
        </Tekst>
      </Tekst>
      <a
        href={() => false}
        onClick={() => regulisiKontaktKlik()}
        className="kontaktirajte-nas"
      >
        {jezik === "srpski" ? "КОНТАКТИРАЈТЕ НАС" : "CONTACT US"}
      </a>
      <Naslov>
        {jezik === "srpski"
          ? "Међународна ликовна колонија - Калудра"
          : "International Art Colony - Kaludra"}
      </Naslov>
      <br />
      <Tekst>
        {jezik === "srpski"
          ? "У нашем селу се, сада већ традиционално, одржава ликовна колонија. Пејзажи које Калудра пружа, дају инспирацију реномираним умјетницима из региона,  али и из остатка свијета.  Поносни смо њихови домаћини."
          : "In our village, now traditionally, an art colony is held. The landscapes that Kaludra provides, inspire renowned artists from the region, but also from the rest of the world. We are proud to be their hosts."}
      </Tekst>
      <div className="karusel-kolonija">
        <Carousel
          strelice={true}
          slike={slike}
          animation={"slide"}
          className={"kolonija restoran"}
        />
      </div>
    </div>
  );
};

const mapStateToProps = ({ jezik }) => ({
  jezik: jezik.jezik,
});

export default connect(mapStateToProps)(OrganizacijaDogadjaja);
