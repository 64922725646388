import React, { useRef, useState } from "react";
import "./forma-restoran.styles.css";
import { connect } from "react-redux";

import emailjs from "emailjs-com";

import ReactJsAlert from "reactjs-alert";
import Input from "../../Input/input.component";
import Kombo from "../../Kombo/kombo.component";
import BookingButton from "../../zaglavlje/Book dugme/bookingButton.component";

const FormaRestoran = ({ jezik }) => {
  const form = useRef();
  const [alertVisible, setAlertVisible] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    let formProps = Object.fromEntries(formData);

    formProps = {
      ...formProps,
      type: "Ресторан",
      dateTo: "/",
      email: "/",
      rooms: "/",
    };

    emailjs
      .send(
        "gmail-service",
        "template_pkel8ss",
        formProps,
        process.env.REACT_APP_EMAILJS_USERID
      )
      .then(
        (result) => {
          console.log(result.text);
          setAlertVisible(true);
        },
        (error) => {
          console.log(error.text);
        }
      );

    e.target.reset();
  };

  return (
    <div className="forma-restoran">
      <form ref={form} onSubmit={sendEmail}>
        <Input
          tip="text"
          plejsholder={jezik === "srpski" ? "Име и презиме" : "Full name"}
          name="name"
          required={true}
        />
        <Input
          tip="text"
          plejsholder={jezik === "srpski" ? "Број телефона" : "Phone number"}
          name="mobile"
          required={true}
        />
        <Input
          tip="date"
          id="date-input"
          min="2020-01-01"
          max="2040-01-01"
          name="dateOf"
          required={true}
        />
        <div className="kombo-restoran">
          <Kombo
            placeholder={jezik === "srpski" ? "Вријеме" : "Time"}
            name="time"
            required={true}
          />

          <Kombo
            placeholder={jezik === "srpski" ? "Одрасли" : "Adults"}
            odrasli={true}
            name="adults"
            required={true}
          />
          <Kombo
            placeholder={jezik === "srpski" ? "Дјеца" : "Kids"}
            djeca={true}
            name="kids"
            required={true}
          />
        </div>
        <br />
        <textarea
          id="w3review"
          name="info"
          rows="6"
          className="poruka"
          placeholder={
            jezik === "srpski" ? "Напомене за кухињу" : "Notes for the kitchen"
          }
        />
        <BookingButton restoran="true">
          {jezik === "srpski" ? "Резервишите" : "Make a reservation"}
        </BookingButton>
      </form>
      <ReactJsAlert
        className="alert-box"
        button={jezik === "srpski" ? "У реду" : "Close"}
        status={alertVisible}
        type="success"
        title={
          jezik === "srpski"
            ? "Неко ће вас ускоро контактирати како би потврдили вашу резервацију. Хвала!"
            : "Someone will contact you shortly to confirm your reservation. Thank you!"
        }
        Close={() => setAlertVisible(false)}
      />
    </div>
  );
};

const mapStateToProps = ({ jezik }) => ({
  jezik: jezik.jezik,
});

export default connect(mapStateToProps)(FormaRestoran);
