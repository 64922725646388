import React from "react";
import "./pocetnaSlika.styles.css";

const pocetnaSlika = ({ slika, naslov, setLoading }) => {
  return (
    <div className="o-nama-slika">
      <h1 className="naslovOnama">{naslov}</h1>
      <img
        src={slika}
        alt=""
        className="slikaOnama"
        onLoad={() => {
          setLoading(false);
          document
            .getElementsByClassName("App")[0]
            .classList.add("fade-in-app");
        }}
      />
    </div>
  );
};

export default pocetnaSlika;
