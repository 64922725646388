import React from "react";
import { connect } from "react-redux";
import "./dugmeRezervacija.styles.css";

import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import FormaSmjestaj from "../forme/forma-smjestaj/forma-smjestaj.component";

const DugmeRezervacija = ({ jezik }) => {
  return (
    <div className="wrap">
      <Popup
        trigger={
          <button className="button">
            {jezik === "srpski" ? "Резервације" : "Reservations"}
          </button>
        }
        modal
        nested
      >
        {(close) => (
          <div className="rezervacija-forma">
            <button className="close" onClick={close}>
              &times;
            </button>
            <FormaSmjestaj />
          </div>
        )}
      </Popup>
    </div>
  );
};

const mapStateToProps = ({ jezik }) => ({
  jezik: jezik.jezik,
});

export default connect(mapStateToProps)(DugmeRezervacija);
