import "./App.css";
import React, { useLayoutEffect, useState } from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import Homepage from "./pages/Homepage/hompage.component";
import Smjestaj from "./pages/Smjestaj/smjestaj.component";
import Restoran from "./pages/Restoran/restoran.component";
import OrganizacijaDogadjaja from "./pages/Organizacija-dogadjaja/organizacija-dogadjaja.component";
import ONama from "./pages/O-nama/o-nama.component";
import Zaglavlje from "./components/zaglavlje/zaglavlje.component";
import Kontakt from "./pages/Kontakt/kontakt.component";
import Dno from "./components/dno/dno.component";
import PonudaZaGoste from "./pages/Ponuda za goste/ponuda-za-goste.component";
import RingLoader from "react-spinners/ClipLoader";

const App = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(true);

  useLayoutEffect(() => {
    document.querySelector("body").scrollTo(0, 0);

    return () => {
      setLoading(true);
      document.getElementsByClassName("App")[0].classList.remove("fade-in-app");
    };
  }, [location]);

  return (
    <div>
      <div className={loading ? "loader" : "loader-hidden"}>
        <RingLoader
          className="loader"
          color={"green"}
          loading={loading}
          size={50}
        />
      </div>
      <div className="App">
        <Zaglavlje />
        <Switch>
          <Route
            exact
            path="/"
            component={() => <Homepage setLoading={setLoading} />}
          />
          <Route
            exact
            path="/smjestaj"
            component={() => <Smjestaj setLoading={setLoading} />}
          />
          <Route
            exact
            path="/restoran"
            component={() => <Restoran setLoading={setLoading} />}
          />

          <Route
            exact
            path="/organizuj-dogadjaj"
            component={() => <OrganizacijaDogadjaja setLoading={setLoading} />}
          />
          <Route
            exact
            path="/o-nama"
            component={() => <ONama setLoading={setLoading} />}
          />

          <Route
            exact
            path="/kontakt"
            component={() => <Kontakt setLoading={setLoading} />}
          />
          <Route
            exact
            path="/ponuda-za-goste"
            component={() => <PonudaZaGoste setLoading={setLoading} />}
          />
          <Route
            exact
            path="/accommodation"
            component={() => <Smjestaj setLoading={setLoading} />}
          />
          <Route
            exact
            path="/restaurant"
            component={() => <Restoran setLoading={setLoading} />}
          />

          <Route
            exact
            path="/organize-event"
            component={() => <OrganizacijaDogadjaja setLoading={setLoading} />}
          />
          <Route
            exact
            path="/about-us"
            component={() => <ONama setLoading={setLoading} />}
          />

          <Route
            exact
            path="/contact"
            component={() => <Kontakt setLoading={setLoading} />}
          />
          <Route
            exact
            path="/offer-for-guests"
            component={() => <PonudaZaGoste setLoading={setLoading} />}
          />
        </Switch>
        <Dno />
      </div>
    </div>
  );
};

export default App;
