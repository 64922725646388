import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {useHistory} from 'react-router-dom';
import $ from 'jquery';
import './ponuda.styles.css';
import {Row, Col} from 'react-bootstrap';
import img1 from './ponuda-slike 450x600/1.jpg';
import img2 from './ponuda-slike 450x600/2.jpg';
import img3 from './ponuda-slike 450x600/3.jpg';
import img4 from './ponuda-slike 450x600/4.jpg';
import Naslov from '../Naslov/naslov.component';


function isScrolledIntoView(elem) {
  var docViewTop = $(window).scrollTop();
  var docViewBottom = docViewTop + $(window).height();

  var elemTop = $(elem).offset().top;
  var elemBottom = elemTop + $(elem).height();

  return (elemBottom <= docViewBottom + 85);
}

$('html, body').scroll(function() {
  $(".kolona").each(function() {
    if (isScrolledIntoView($(this))) {
        this.classList.add('hover');
    }else{
      this.classList.remove('hover');
    }
  });
});



const Ponuda = ({jezik}) => {

    useEffect(() => {
      if(window.innerWidth < 738){
        $(".kolona").each(function(){
          this.classList.remove('kolona2');
        });
      }
    }, []);
    
    let history = useHistory();

    function srediKlik(link){
        if(jezik==='srpski'){
        history.push(link);
        }else{
            switch(link){
                  case '/smjestaj':
                    history.push('/accommodation');
                    break;
                  case '/restoran':
                    history.push('/restaurant'); 
                    break;
                  case '/organizuj-dogadjaj':
                    history.push('/organize-event'); 
                    break;
                  case '/ponuda-za-goste':
                      history.push('/offer-for-guests');
                      break;  
                  default:
                    history.push('/');  
                    break;
            }
        }    
       
    }
    
    return (
        <div className='ponuda'>
            <div className='usluge'>
            <Naslov >{jezik==='srpski' ? 'Шта нудимо?' : 'Our services'}</Naslov>
            </div>
           <Row className='red'>
           
          <Col className='kolona kolona2'>
          <h1 className='traka prvi' onClick={() => srediKlik("/smjestaj")}>{jezik==='srpski' ? 'Смјештај' : 'Accommodation'}</h1>
          <img src={img1} alt="" className='karta karta2' onClick={() => srediKlik("/smjestaj")}/>
          </Col>
          
          <Col className='kolona kolona2'>
          <h1 className='traka drugi' onClick={() => srediKlik("/restoran")}>{jezik==='srpski' ? 'Ресторан' : 'Restaurant'}</h1>
          <img src={img2} alt=""  className='karta karta2' onClick={() => srediKlik("/restoran")}/></Col>
          
          <Col className='kolona kolona2'>
          <h1 className='traka treci' onClick={() => srediKlik("/organizuj-dogadjaj")}>{jezik==='srpski' ? 'Организација догађаја' : 'Event organizing'}</h1>
          <img src={img3} alt=""  className='karta karta2' onClick={() => srediKlik("/organizuj-dogadjaj")}/></Col>
       
          <Col className='kolona kolona2'>
          <h1 className='traka cetvrti' onClick={() => srediKlik("/ponuda-za-goste")}>{jezik==='srpski' ? 'Понуда за госте' : 'Offer for guests'}</h1>
          <img src={img4} alt=""  className='karta karta2' onClick={() => srediKlik("/ponuda-za-goste")}/></Col>

        </Row>   
           
        </div>
    )
}


const mapStateToProps = ({jezik}) =>({
    jezik: jezik.jezik
});

export default connect(mapStateToProps)(Ponuda);
