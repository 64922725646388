import React, { useRef } from "react";
import { connect } from "react-redux";

import emailjs from "emailjs-com";

import PocetnaSlika from "../../components/Pocetna slika/pocetnaSlika";
import img from "../../fotografije/kontakt.jpg";
import { Row, Col } from "react-bootstrap";
import Naslov from "../../components/Naslov/naslov.component";
import Tekst from "../../components/Tekst/tekst.component";
import Lokacija from "../../components/Lokacija/lokacija.component";
import Input from "../../components/Input/input.component";
import "./kontakt.styles.css";
import BookingButton from "../../components/zaglavlje/Book dugme/bookingButton.component";

const Kontakt = ({ jezik, setLoading }) => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "gmail-service",
        "template_jqcjnbq",
        form.current,
        process.env.REACT_APP_EMAILJS_USERID
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );

    e.target.reset();
  };

  return (
    <div className="kontakt-sve">
      <PocetnaSlika
        slika={img}
        setLoading={setLoading}
        naslov={jezik === "srpski" ? "Контакт" : "Contact"}
      />
      <Row>
        <Col md={4}>
          <div className="kontakt-dimenzije">
            <Naslov>
              {jezik === "srpski" ? "Контакт инфо" : "Contact info"}
            </Naslov>
            <div className="kontakt-info-tekst">
              <Tekst>
                <span className="siva-slova">
                  {jezik === "srpski" ? "Адреса: " : "Address:"}
                </span>
                {jezik === "srpski"
                  ? "Калудра, Беране 84300"
                  : " Kaludra, Berane 84300"}
              </Tekst>
              <Tekst>
                <span className="siva-slova">
                  {jezik === "srpski" ? "Телефон: " : "Phone: "}
                </span>
                +382 (0) 69 047 834
              </Tekst>
              <Tekst>
                <span className="siva-slova">
                  {jezik === "srpski" ? "Е-маил: " : "E-mail: "}
                </span>{" "}
                lucplus@t-com.me
              </Tekst>
            </div>
          </div>
        </Col>
        <Col md={4}>
          <div className="kontakt-forma">
            <form ref={form} onSubmit={sendEmail}>
              <Input
                tip="text"
                plejsholder={jezik === "srpski" ? "Ваше име" : "Your name"}
                name={"name"}
                required={true}
              />
              <Input
                tip="email"
                plejsholder={jezik === "srpski" ? "Е-маил" : "E-mail"}
                name={"email"}
                required={true}
              />
              <textarea
                id="w3review"
                required
                name="message"
                rows="6"
                className="poruka"
                placeholder={
                  jezik === "srpski" ? "Ваша порука" : "Your message"
                }
              />
              <br />
              <BookingButton kontakt="true">
                {jezik === "srpski" ? "Пошаљите емаил" : "Send e-mail"}
              </BookingButton>
            </form>
          </div>
        </Col>
      </Row>
      <Lokacija />
    </div>
  );
};

const mapStateToProps = ({ jezik }) => ({
  jezik: jezik.jezik,
});

export default connect(mapStateToProps)(Kontakt);
