import React, { useEffect } from "react";
import "./thumbnail.styles.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import $ from "jquery";

import dvokrevetna1 from "./fotografija/dvokrevetna/1.jpg";
import dvokrevetna2 from "./fotografija/dvokrevetna/2.jpg";
import trokrevetna1 from "./fotografija/trokrevetna/1.jpg";
import trokrevetna2 from "./fotografija/trokrevetna/2.jpg";
import cetvorokrevetna1 from "./fotografija/cetvorokrevetna/1.jpg";
import cetvorokrevetna2 from "./fotografija/cetvorokrevetna/2.jpg";
import radonja1 from "./fotografija/radonja/1.jpg";
import radonja2 from "./fotografija/radonja/2.jpg";

const Thumnail = ({ soba }) => {
  useEffect(() => {
    $(".thumb-slika").click(function () {
      if (window.innerWidth > 1535) {
        var src = $(this).find("img").attr("src");
        var modal;
        function removeModal() {
          modal.remove();
          $("body").off("keyup.modal-close");
        }
        modal = $("<div>")
          .css({
            background: "RGBA(0,0,0,.5) url(" + src + ") no-repeat center",
            backgroundSize: "contain",
            width: "100%",
            height: "100%",
            position: "fixed",
            zIndex: "10000",
            top: "0",
            left: "0",
            cursor: "zoom-out",
          })
          .click(function () {
            removeModal();
          })
          .appendTo("body");
        $("body").on("keyup.modal-close", function (e) {
          if (e.key === "Escape") {
            removeModal();
          }
        });
      }
    });
  }, [soba]);

  var foto1 = dvokrevetna1;
  var foto2 = dvokrevetna2;

  switch (soba) {
    case 0:
      foto1 = dvokrevetna1;
      foto2 = dvokrevetna2;

      break;
    case 1:
      foto1 = trokrevetna1;
      foto2 = trokrevetna2;

      break;
    case 2:
      foto1 = cetvorokrevetna1;
      foto2 = cetvorokrevetna2;

      break;
    case 3:
      foto1 = radonja1;
      foto2 = radonja2;
      break;
    default:
      break;
  }

  return (
    <div className="thumb-sve">
      <TransitionGroup>
        <CSSTransition
          key={soba}
          appear={true}
          timeout={1500}
          classNames="fade"
        >
          <Carousel
            autoPlay
            interval="5000"
            transitionTime="1000"
            showArrows={false}
            swipeable
          >
            <div className="thumb-slika">
              <img src={foto1} alt="" />
            </div>
            <div className="thumb-slika">
              <img src={foto2} alt="" />
            </div>
          </Carousel>
        </CSSTransition>
      </TransitionGroup>
    </div>
  );
};

export default Thumnail;
