import React, { useEffect } from "react";
import $ from "jquery";
import "./input.styles.css";

const Input = ({ tip, plejsholder, min, max, id, klasa, name, required }) => {
  useEffect(() => {
    var today = new Date();

    if (document.querySelector("#date-input") !== null) {
      document.querySelector("#date-input").valueAsDate = new Date();
    }

    if (document.querySelector("#date-input2") !== null) {
      var tomorrow = new Date();
      tomorrow.setDate(today.getDate() + 1);
      document.querySelector("#date-input2").valueAsDate = tomorrow;
    }

    $("#date-input").on("change", function () {
      $("#date-input").css({ color: "#404040" });
    });
    $("#date-input2").on("change", function () {
      $("#date-input2").css({ color: "#404040" });
    });
  }, []);

  return (
    <input
      id={id}
      type={tip}
      placeholder={plejsholder}
      className={klasa}
      min={min}
      max={max}
      name={name}
      required={required}
    />
  );
};

export default Input;
