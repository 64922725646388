import React from "react";
import "./dugmad.styles.css";

import { GiBed, GiHouse } from "react-icons/gi";

const Dugmad = ({ soba, jezik }) => {
  return (
    <div className="dugmad">
      <button
        className={soba === 0 ? "nav-dugme nav-dugme-aktivno" : "nav-dugme"}
      >
        <div className="kreveti-icons">
          <span className="broj-kreveta">2x</span>
          <GiBed />
        </div>
        <div className="kreveti-naziv">
          {jezik === "srpski" ? "Двокреветна" : "Two bed"}
        </div>
      </button>
      <button
        className={soba === 1 ? "nav-dugme nav-dugme-aktivno" : "nav-dugme"}
      >
        <div className="kreveti-icons">
          <span className="broj-kreveta">3x</span>
          <GiBed />
        </div>
        <div className="kreveti-naziv">
          {jezik === "srpski" ? "Трокреветна" : "Three bed"}
        </div>
      </button>
      <button
        className={soba === 2 ? "nav-dugme nav-dugme-aktivno" : "nav-dugme"}
      >
        <div className="kreveti-icons">
          <span className="broj-kreveta">4x</span>
          <GiBed />
        </div>
        <div className="kreveti-naziv">
          {jezik === "srpski" ? "Четворокреветна" : "Four bed"}
        </div>
      </button>
      <button
        className={soba === 3 ? "nav-dugme nav-dugme-aktivno" : "nav-dugme"}
      >
        <div className="kreveti-icons">
          <GiHouse />
        </div>
        <div className="kreveti-naziv">
          {jezik === "srpski" ? "Апартман" : "Apartman"}
        </div>
      </button>
    </div>
  );
};

export default Dugmad;
