import React from 'react';
import './tekst.styles.css';

const Tekst = (props) => {
    return (
        <h2 className='nasa-vizija'>{props.children}</h2>

    )
}

export default Tekst;
