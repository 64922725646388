import React, { useState, useEffect } from "react";
import Dugmad from "./Dugmad/dugmad.component";
import "./smjestaj-opcije.styles.css";

import $ from "jquery";
import Thumnail from "./Thumbnail galerija/thumbnail.component";
import ScaleLoader from "react-spinners/ScaleLoader";

const SmjestajOpcije = ({ soba, setSoba, jezik }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    $(".nav-dugme").each(function () {
      $(this).click(function () {
        setSoba($(this).index());
        $(".thumbs").fadeIn("slow");
        setLoading(true);
      });
    });
  }, [setSoba]);

  useEffect(() => {
    var image = new Image();
    image.src = $(".thumb-slika > img").attr("src");

    image.onload = function () {
      $(".thumb-slika").css({ "pointer-events": "all" });
      setLoading(false);
    };
  }, [soba]);

  return (
    <div className="smjestaj-opcije">
      <div className={loading ? "loader2" : "loader-hidden"}>
        <ScaleLoader
          className="loader"
          color={"rgb(170, 152, 102)"}
          loading={loading}
          size={40}
        />
      </div>
      <Dugmad soba={soba} jezik={jezik} />
      <div className={loading ? "smjestaj-sadrzaj" : "smestaj-sadrzaj-ucitan"}>
        <Thumnail soba={soba} />
      </div>
    </div>
  );
};

export default SmjestajOpcije;
