import JezikActionTypes from './jezik.types';

const INITIAL_STATE = {
    jezik: "srpski"
};



const jezikReducer = (state = INITIAL_STATE, action) => {
    switch(action.type){
        case JezikActionTypes.PROMIJENI_ENGLESKI:         
        return{
            ...state,
            jezik: "srpski"
        };
        case JezikActionTypes.PROMIJENI_SRPSKI:         
        return{
            ...state,
            jezik: "engleski"
        };
       
        default: return state
    } 
};

export default jezikReducer;