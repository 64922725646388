import React from 'react';
import { FaFacebookF } from 'react-icons/fa';
import './facebook.styles.css';

const Facebook = ({dno}) => {
    return (
        <a href="https://www.facebook.com/%D0%9E%D0%BA%D0%B0-%D0%B8-%D0%BF%D0%BE-560431074107141" target='_blank' rel="noreferrer" className='facebook'>
          <FaFacebookF className={dno ? 'fejs2' : 'fejs'}/>
        </a>
    )
}

export default Facebook;
