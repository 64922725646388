import React from 'react';
import { GrInstagram } from 'react-icons/gr';
import './instagram.styles.css';

const Instagram = ({dno}) => {
    return (
        <a href="https://www.instagram.com/okaipo/" target='_blank' rel="noreferrer" className='instagram'>
          <GrInstagram className={dno ? 'insta2' : 'insta'}/>
          </a>
    )
}

export default Instagram;
