import React from "react";
import { connect } from "react-redux";

import img1 from "../../assets/slike za karusel/img1.jpg";
import img2 from "../../assets/slike za karusel/img2.jpg";
import img3 from "../../assets/slike za karusel/img3.jpg";

import prvi from "../../assets/slike za komentar karusel/prvi.jpg";
import drugi from "../../assets/slike za komentar karusel/drugi.jpg";
import treci from "../../assets/slike za komentar karusel/treci.jpg";
import cetvrti from "../../assets/slike za komentar karusel/cetvrti.jpg";
import peti from "../../assets/slike za komentar karusel/peti.jpg";
import sesti from "../../assets/slike za komentar karusel/sesti.jpg";
import sedmi from "../../assets/slike za komentar karusel/sedmi.jpg";
import osmi from "../../assets/slike za komentar karusel/osmi.jpg";

import Carousel from "../../components/karusel/Carousel.component";
import "./homepage.styles.css";
import DugmeRezervacija from "../../components/Dugme za rezervaciju/DugmeRezervacija.component";
import strijela from "./slike/strijela.png";
import NaslovnaONama from "../../components/naslovna-o-nama/naslovna.o-nama.component";
import Ponuda from "../../components/ponuda/ponuda.component";
import Naslov from "../../components/Naslov/naslov.component";

const slike = [
  {
    imageURL: prvi,
    number: 1,
  },
  {
    imageURL: drugi,
    number: 2,
  },
  {
    imageURL: treci,
    number: 3,
  },
  {
    imageURL: cetvrti,
    number: 4,
  },
  {
    imageURL: peti,
    number: 5,
  },
  {
    imageURL: sesti,
    number: 6,
  },
  {
    imageURL: sedmi,
    number: 7,
  },
  {
    imageURL: osmi,
    number: 8,
  },
];

const slikeNaslov = [
  {
    imageURL: img1,
    number: 1,
  },
  {
    imageURL: img2,
    number: 2,
  },
  {
    imageURL: img3,
    number: 3,
  },
];

const Homepage = ({ jezik, setLoading }) => (
  <div className="homepage">
    <div className="regulisi">
      <div className="dugme-strelica">
        <DugmeRezervacija className="dugme" />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <img src={strijela} className="strijela" alt="" />
      </div>
      <div className="zaj">
        <p className="oka">{jezik === "srpski" ? "Ока и по" : "Oka i po"}</p>
        <p className="rijeci">
          {jezik === "srpski"
            ? "Гдје бајка оживи"
            : "Where the fairy tail comes alive"}
        </p>
      </div>
      <Carousel
        slike={slikeNaslov}
        animation={"fade"}
        minVh={true}
        setLoading={setLoading}
      />
    </div>
    <NaslovnaONama />
    <Ponuda />
    <Naslov>
      {jezik === "srpski" ? "Наши гости о нама" : "Our guests about us"}
    </Naslov>
    <div className="karusel-komentari-container">
      <Carousel
        slike={slike}
        animation={"slide"}
        className={"komentar komentari-karusel"}
      />
    </div>
  </div>
);

const mapStateToProps = ({ jezik }) => ({
  jezik: jezik.jezik,
});

export default connect(mapStateToProps)(Homepage);
