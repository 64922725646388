import React, { useEffect } from "react";
import "./karusel.styles.css";
import {
  MDBCarousel,
  MDBCarouselInner,
  MDBCarouselItem,
  MDBView,
} from "mdbreact";
import $ from "jquery";

const Carousel = ({
  slike,
  animation,
  minVh,
  strelice,
  className,
  setLoading,
}) => {
  useEffect(() => {
    if (strelice) {
      $(".carousel-control-next").addClass("strelica-desno");
      $(".carousel-control-prev").addClass("strelica-lijevo");
    }
  }, [strelice]);

  return (
    <div className="container2">
      <MDBCarousel
        activeItem={1}
        length={slike.length}
        showControls={minVh ? false : true}
        showIndicators={false}
        className="z-depth-1"
        interval={6000}
        slide={minVh ? false : true}
        fade={minVh ? "true" : "false"}
      >
        <MDBCarouselInner>
          {slike.map((slika) => {
            return (
              <MDBCarouselItem
                key={slika.number}
                className={animation}
                itemId={slika.number}
              >
                <MDBView className={className}>
                  <img
                    src={slika.imageURL}
                    onLoad={() => {
                      if (setLoading) {
                        setLoading(false);
                        document
                          .getElementsByClassName("App")[0]
                          .classList.add("fade-in-app");
                      }
                    }}
                    alt={`slide ${slika.number}`}
                    className={`d-flex align-items-center justify-content-center ${
                      minVh ? "min-vh-100" : ""
                    }`}
                  />
                </MDBView>
              </MDBCarouselItem>
            );
          })}
        </MDBCarouselInner>
      </MDBCarousel>
    </div>
  );
};

export default Carousel;
